export const GET_MEDIALIST_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIALIST_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIALIST__ERROR = "GET_MEDIA__ERROR";
export const CLEAR_MYPROFILE__FLAG = "CLEAR_GET_MEDIA__FLAG";

export const GET_FILE_URL_MYPROFILE_REQUEST = "GET_FILE_URL_REQUEST";
export const GET_FILE_URL_MYPROFILE_SUCCESS = 'GET_FILE_URL_SUCCESS';
export const GET_FILE_URL_MYPROFILE_ERROR = 'GET_FILE_URL_URL_ERROR';

export const GET_FILE_URL_CLEAR_FLAG = "GET_FILE_URL_CLEAR_FLAG";
