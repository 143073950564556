// import { REHYDRATE } from "redux-persist/es/constants";
import {
  GET_FILE_URL_ERROR,
  GET_FILE_URL_FLAG,
  GET_FILE_URL_REQUEST,
  GET_FILE_URL_SUCCESS,
  GET_MEDICAL_MEDIA_REQUEST,
  GET_MEDICAL_MEDIA_SUCCESS,
  GET_MEDICAL_MEDIA__ERROR,
  GET_MEDICAL_MEDIA__FLAG,
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS__ERROR,
  UPLOAD_DOCUMENTS__FLAG,
  DELETE_FILE_RECORD_REQUEST,
  DELETE_FILE_RECORD_SUCCESS,
  DELETE_FILE_RECORD_ERROR,
  DELETE_FILE_RECORD_FLAG,
  MEDICAL_HISTORY_FLAG
} from "../type/MedicalHistoryMedia";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  medicalMediaSuccess: false,
  medicalMediaError: false,
  medicalMediaRequest: false,
  medicalMediaData: [],
  medicalMediaErrorMessage: [],

  getFileUrlSuccess: false,
  getFileUrlRequest: false,
  getFileUrlError: false,
  getFileUrlFlag: false,
  getFileUrlData: [],

  uploadDocumentRequest: false,
  uploadDocumentSuccess: false,
  uploadDocumentError: false,
  uploadDocumentData: '',
  uploadDocumentErrorData: {},

  deleteFileRecordRequest: false,
  deleteFileRecordSuccess: false,
  deleteFileRecordError: false,
  deleteFileRecordData: false,
  deleteFileRecordErrorMessage: {},
};
export default function MedicalMediaUploadReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.MedicalMediaUploadReducer) {
        return { ...action.payload.MedicalMediaUploadReducer };
      }
      return { ...state };
    }
    case GET_MEDICAL_MEDIA_REQUEST:
      return {
        ...state,
        medicalMediaSuccess: false,
        medicalMediaError: false,
        medicalMediaRequest: false,
      };

    case GET_MEDICAL_MEDIA_SUCCESS:
      return {
        ...state,
        medicalMediaSuccess: true,
        medicalMediaError: false,
        medicalMediaRequest: false,
        medicalMediaData: action.payload
      };
    case GET_MEDICAL_MEDIA__ERROR:
      return {
        ...state,
        medicalMediaSuccess: false,
        medicalMediaRequest: false,
        medicalMediaError: true,
        medicalMediaErrorMessage: action.payload?.response?.data?.message
      };
    case GET_FILE_URL_REQUEST:
      return {
        ...state,
        getFileUrlSuccess: false,
        getFileUrlRequest: true,
        getFileUrlError: false
      };

    case GET_FILE_URL_SUCCESS:
      return {
        ...state,
        getFileUrlSuccess: true,
        getFileUrlError: false,
        getFileUrlRequest: false,
        getFileUrlData: action.payload?.data?.data
      };
    case GET_FILE_URL_ERROR:
      return {
        ...state,
        getFileUrlSuccess: false,
        getFileUrlRequest: false,
        getFileUrlError: true
      };

    case UPLOAD_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadDocumentSuccess: false,
        uploadDocumentError: false,
        uploadDocumentRequest: true
      };

    case UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadDocumentSuccess: true,
        uploadDocumentRequest: false,
        uploadDocumentError: false,
        uploadDocumentData: action.payload,
      };
    case UPLOAD_DOCUMENTS__ERROR:
      return {
        ...state,
        uploadDocumentSuccess: false,
        uploadDocumentError: true,
        uploadDocumentRequest: false,
        uploadDocumentErrorData: action.payload?.response?.data?.message
      };

    case UPLOAD_DOCUMENTS__FLAG:
      return {
        ...state,
        uploadDocumentSuccess: false,
        uploadDocumentError: false,
        uploadDocumentRequest: false,
        uploadDocumentErrorData: {},
        uploadDocumentData: []
      };

    case DELETE_FILE_RECORD_REQUEST:
      return {
        ...state,
        deleteFileRecordRequest: false,
        deleteFileRecordSuccess: false,
        deleteFileRecordError: false
      };

    case DELETE_FILE_RECORD_SUCCESS:
      return {
        ...state,
        deleteFileRecordSuccess: true,
        deleteFileRecordError: false,
        deleteFileRecordRequest: false,
        deleteFileRecordData: action.payload,
      };

    case DELETE_FILE_RECORD_ERROR:
      return {
        ...state,
        deleteFileRecordSuccess: false,
        deleteFileRecordError: true,
        deleteFileRecordErrorMessage: action.payload?.response?.data?.message
      };

    case DELETE_FILE_RECORD_FLAG:
      return {
        ...state,
        deleteFileRecordRequest: false,
        deleteFileRecordSuccess: false,
        deleteFileRecordError: false,
        deleteFileRecordData: false,
        deleteFileRecordErrorMessage: {},
      };

    case GET_MEDICAL_MEDIA__FLAG:
      return {
        ...state,
        medicalMediaSuccess: false,
        medicalMediaError: false,
        medicalMediaRequest: false,
      };

    case GET_FILE_URL_FLAG:
      return {
        ...state,
        getFileUrlSuccess: false,
        getFileUrlError: false,
        getFileUrlData: [],
      };

    case MEDICAL_HISTORY_FLAG:
      return {
        ...state,
        deleteFileRecordSuccess: false,
        // getFileUrlData:[],
        medicalMediaSuccess: false,
        // getFileUrlSuccess:false,
        uploadDocumentSuccess: false,
        uploadDocumentError: false,
        uploadDocumentRequest: false,
        uploadDocumentErrorData: {},
        uploadDocumentData: [],

        medicalMediaSuccess: false,
        medicalMediaError: false,
        medicalMediaRequest: false,
        medicalMediaErrorMessage: [],
      };
    default:
      return state;
  }
}