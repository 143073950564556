import { store, persistor } from "./redux/store";
import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { PersistGate } from "redux-persist/integration/react";
import { CircularProgress, LinearProgress } from "@material-ui/core";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import { routes } from "config/endpoints";

const LoginPage = lazy(() => import ("views/LoginPage/LoginPage.js" ));
const PresentationPage = lazy(() => import ("views/PresentationPage/PresentationPage.js"));
const SignUp = lazy(() => import ("views/SignUp/SignUp"));
const Confirmation = lazy(() => import("views/OrderConfirmation/ConfirmationDetails"));
const SubscriptionConfirmation = lazy(() => import("views/OrderConfirmation/SubscriptionConfirmation"));
const ProductDetails = lazy(() => import ("views/ProductSection/ProductDetails"));
const Cart = lazy(() => import ("views/ProductSection/Cart"));
const PrivacyPolicy = lazy(() => import("views/PrivacyPolicy/PrivacyPolicy"));
const MyOrders = lazy(() => import ("views/MyOrders/MyOrders"));
const ViewMyOrderDetails = lazy(()=> import ("views/MyOrders/ViewMyOrderDetails/ViewMyOrderDetails"));
const UserSubscription = lazy(() => import ("views/MySubscriptions/UserSubscription"));
const ContactUs = lazy(() => import("views/ContactUs/ContactUs"));
const ShippingInfo = lazy(() => import("views/ShippingInfo/ShippingInfo"));
const Products = lazy(() => import("views/Products/Products"));
const ForgotPassword = lazy(() => import("views/ForgotPassword/ForgotPassword"));
const Subscription = lazy(() => import("views/Subscription/Subscription"));
const MyProfile = lazy(() => import ("views/MyProfile/MyProfile"));
const OrderPlace = lazy(() => import("views/PlaceOrder/PlaceOrder"));
const ShippingSuccess = lazy(() => import("views/ShippingInfo/Success/ShippingSuccess"));
const Verification = lazy(() => import("views/Verification/Verification"));
const MedicalHistory = lazy(() => import("views/MedicalHistory/MedicalHistory"));

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_CONFIG,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const LoggedIn = localStorage.getItem("LoggedIn");


const history = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <React.Suspense fallback={  <LinearProgress/>}>
      <Router history={history}>
        <PersistGate persistor={persistor}>
          <Switch>
            <Route exact path={routes.signIn}  component={LoginPage} />
            <Route exact path={routes.orderDetails} component={OrderPlace} />
            <Route
              exact
              path={routes.subscriptionPackages}
              component={Subscription}
            />
            <Route exact path={routes.contactUs} component={ContactUs} />
            <Route exact path={routes.signUp} component={SignUp} />
            <Route
              exact
              path={`${routes.subscriptionConfirmation}/:id`}
              component={SubscriptionConfirmation}
            />
            <Route
              exact
              path={`${routes.forgotPassword}/:id`}
              component={ForgotPassword}
            />
            <Route exact path={routes.verification} component={Verification} />
            <Route
              exact
              path={`${routes.orderConfirmation}/:id`}
              component={Confirmation}
            />
            <Route
              exact
              path={`${routes.productDetail}/:id/:name`}
              component={ProductDetails}
            />
            <Route exact path={routes.products} component={Products} />
            <Route exact path={routes.medicalHistory} component={MedicalHistory} />
            <Route path={`${routes.carts}/:id`} component={Cart} />
            <Route
              exact
              path={`${routes.shippingInfo}/:id`}
              component={ShippingInfo}
            />
            <Route exact path={routes.privacyPolicy} component={PrivacyPolicy} />
            <Route exact path={routes.myOrders} component={MyOrders} />
            <Route exact path={`${routes.myOrdersDetails}/:order`} component={ViewMyOrderDetails} />
            <Route exact path={routes.mySubscription} component={UserSubscription} />
            <Route
              exact
              path={routes.myProfile}
              component={MyProfile}
            />
            <Route exact path="/" component={PresentationPage} />
            <Route exact path={routes.orderPlaced} component={ShippingSuccess} />
          </Switch>
        </PersistGate>
      </Router>
      </React.Suspense>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
