export const GET_ADDRESSES_REQUEST = "GET_ADDRESSES_REQUEST";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_ERROR = "GET_ADDRESSES_ERROR";

export const ADD_ADDRESSES_REQUEST = "ADD_ADDRESSES_REQUEST";
export const ADD_ADDRESSES_SUCCESS = "ADD_ADDRESSES_SUCCESS";
export const ADD_ADDRESSES_ERROR = "ADD_ADDRESSES_ERROR";

export const UPDATE_ADDRESSES_REQUEST = "UPDATE_ADDRESSES_REQUEST";
export const UPDATE_ADDRESSES_SUCCESS = "UPDATE_ADDRESSES_SUCCESS";
export const UPDATE_ADDRESSES_ERROR = "UPDATE_ADDRESSES_ERROR";

export const DELETE_ADDRESSES_REQUEST = "DELETE_ADDRESSES_REQUEST";
export const DELETE_ADDRESSES_SUCCESS = "DELETE_ADDRESSES_SUCCESS";
export const DELETE_ADDRESSES_ERROR = "DELETE_ADDRESSES_ERROR";

export const CLEAR_ADDRESSES_FLAG = "CLEAR_ADDRESSES_FLAG";