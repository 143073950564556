import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);
export const persistor = persistStore(store);
