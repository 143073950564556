import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_EMAIL_REQUEST,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
  FORGOT_PASSWORD_EMAIL_ERROR,
  CLEAR_FORGOT_PASSWORD_FLAG,
} from "../type/ForgotPasswordType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  forgotPasswordMailData: "",
  forgotPasswordMailErrorData: "",
  forgotPasswordMailSuccess: false,
  forgotPasswordMailError: false,
  isForgotPasswordMailLoading: false,

  forgotPasswordSuccess: false,
  forgotPasswordError: false,
  isForgotPasswordLoading: false,
  forgotPasswordData: "",
  forgotPasswordErrorData: "",

  errorCode: ""
};

export default function forgotReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.forgotReducer) {
        return { ...action.payload.forgotReducer };
      }
      return {
        ...state,
      };

    case FORGOT_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        forgotPasswordMailSuccess: false,
        forgotPasswordMailError: false,
        isForgotPasswordMailLoading: true,
      };
    case FORGOT_PASSWORD_EMAIL_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        forgotPasswordMailSuccess: true,
        forgotPasswordMailError: false,
        isForgotPasswordMailLoading: false,
        forgotPasswordMailData: payload,
      };
    case FORGOT_PASSWORD_EMAIL_ERROR:
      return {
        ...state,
        forgotPasswordMailSuccess: false,
        forgotPasswordMailError: true,
        isForgotPasswordMailLoading: false,
        forgotPasswordMailErrorData: action.payload,
      };
      
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordError: false,
        isForgotPasswordLoading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
        forgotPasswordError: false,
        isForgotPasswordLoading: false,
        forgotPasswordData: action.payload,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordError: true,
        isForgotPasswordLoading: false,
        forgotPasswordErrorData: action.payload,
      };
    case CLEAR_FORGOT_PASSWORD_FLAG:
      return {
        forgotPasswordMailSuccess: false,
        forgotPasswordMailError: false,
        isForgotPasswordMailLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordError: false,
        isForgotPasswordLoading: false,
      };
    default:
      return state;
  }
}
