
import { REHYDRATE } from "redux-persist/lib/constants";
import { CLEAR_MYPROFILE__FLAG } from "redux/type/manageMyProfileType";
import { GET_FILE_URL_MYPROFILE_SUCCESS } from "redux/type/manageMyProfileType";
import { GET_FILE_URL_CLEAR_FLAG } from "redux/type/manageMyProfileType";
import { GET_FILE_URL_MYPROFILE_REQUEST } from "redux/type/manageMyProfileType";
import { GET_FILE_URL_MYPROFILE_ERROR } from "redux/type/manageMyProfileType";
import { GET_MEDIALIST_REQUEST } from "redux/type/manageMyProfileType";
import { GET_MEDIALIST_SUCCESS } from "redux/type/manageMyProfileType";
import { GET_MEDIALIST__ERROR } from "redux/type/manageMyProfileType";
  
  const initialState = {
    mediaListRequest: false,
    mediaListSuccess: false,
    mediaListError: false,
    mediaListData: [],
  
    getProfileFileUrlRequest: false,
    getProfileFileUrlSuccess: false,
    getProfileFileUrlError: false,
    getProfileFileUrlData: [],  
    
  };
  
  export default function ManageMyProfileReducer(state = initialState, action) {
    switch (action.type) {
      case REHYDRATE: {
        if (action.payload && action.payload.ManageMyProfileReducer) {
          return { ...action.payload.ManageMyProfileReducer };
        }
        return {
          ...state,
        };
      }

      case GET_MEDIALIST_REQUEST:
        return {
          ...state,
          mediaListRequest: true,
          mediaListSuccess: false,
          mediaListError: false,
        };
      case GET_MEDIALIST_SUCCESS:
        return {
          ...state,
          mediaListRequest: false,
          mediaListSuccess: true,
          mediaListError: false,
          mediaListData: action.payload,
        };
      case GET_MEDIALIST__ERROR:
        return {
          ...state,
          mediaListRequest: false,
          mediaListSuccess: false,
          mediaListError: true,
          mediaListData: [],
        };
    
      case GET_FILE_URL_MYPROFILE_REQUEST:
        return {
          ...state,
          getProfileFileUrlRequest: true,
          getProfileFileUrlSuccess: false,
          getProfileFileUrlError: false,
        };
      case GET_FILE_URL_MYPROFILE_SUCCESS:
        return {
          ...state,
          getProfileFileUrlRequest: false,
          getProfileFileUrlSuccess: true,
          getProfileFileUrlError: false,
          getProfileFileUrlData: action.payload,
        };
      case GET_FILE_URL_MYPROFILE_ERROR:
        return {
          ...state,
          getProfileFileUrlRequest: false,
          getProfileFileUrlSuccess: false,
          getProfileFileUrlError: true,
          getProfileFileUrlData: [],
        };

      case GET_FILE_URL_CLEAR_FLAG: 
         return {
           ...state,
           getProfileFileUrlData:[]
         }  
      
      case CLEAR_MYPROFILE__FLAG:
        return {
          ...state,
          mediaListSuccess: false,
          mediaListError: false,
          mediaListRequest: false,
          mediaListData: [],

          getProfileFileUrlRequest: false,
          getProfileFileUrlSuccess: false,
          getProfileFileUrlError: false,
          // getProfileFileUrlData: [],  
        };
      default:
        return state;
    }
  }
  