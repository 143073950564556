import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_ERROR,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_ERROR,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_CART_ERROR,
  CLEAR_CART_FLAG,
} from "../type/OrderCartType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  addCartData: "",
  addCartSuccess: false,
  addCartError: false,
  isCartLoading: false,

  cartDetails: "",
  getCartSuccess: false,
  getCartError: false,
  isGetCartLoading: false,
  cartDetailsErrorMessage: "",

  updateCart: [],
  isUpdateLoading: false,
  updateCartSuccess: false,

  deleteCartSuccess: false,
  deleteCartError: false,

  addLocalCartLoginSuccess: false
};

export default function addToCartReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.addToCartReducer) {
        return { ...action.payload.addToCartReducer };
      }
      return { ...state };
    }
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        addCartSuccess: false,
        addCartError: false,
        isCartLoading: true,
      };
    case ADD_TO_CART_SUCCESS:    
      // localStorage.setItem("uuid", data.CreateCart.uuid);
      return {
        ...state,
        addCartSuccess: true,
        addCartError: false,
        isCartLoading: false,
        addLocalCartLoginSuccess: action.payload.is_redirect,
        addCartData: action.payload,
      };
      case ADD_TO_CART_ERROR:
      return {
        ...state,
        isCartLoading: false,
        addCartSuccess: false,
        addCartError: true,
        cartDetailsErrorMessage: action.payload.response?.data?.message
      };
    case GET_CART_REQUEST:
      return {
        ...state,
        getCartSuccess: false,
        getCartError: false,
        isGetCartLoading: true
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        getCartSuccess: true,
        getCartError: false,
        isGetCartLoading: false,
        cartDetails: action.payload,
      };
    case GET_CART_ERROR:
      return {
        ...state,
        getCartSuccess: false,
        getCartError: true,
        isGetCartLoading: false,
      };
    case UPDATE_CART_REQUEST:
      return {
        ...state,
        isUpdateLoading: true
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        updateCart: action.payload.data.UpdateCart,
        updateCartSuccess: true,
        isUpdateLoading: false,
      };
    case UPDATE_CART_ERROR:
      return {
        ...state,
        updateCartSuccess: false,
        isUpdateLoading: false,
      };
    case DELETE_CART_REQUEST:
      return {
        ...state,
      };
    case DELETE_CART_SUCCESS:
      return {
        ...state,
        deleteCartSuccess: true,
        deleteCartError: false,
        cartDetails: action.payload,
      };
    case DELETE_CART_ERROR: {
      return {
        ...state,
        deleteCartError: true,
      };
    }
    case CLEAR_CART_FLAG:
      return {
        ...state,
        deleteCartSuccess: false,
        getCartSuccess: false,
        getCartError: false,
        addCartSuccess: false,
        addCartError: false,
        updateCartSuccess: false,
        deleteCartError: false,
        isCartLoading: false,
        isUpdateLoading: false,
        addLocalCartLoginSuccess: false,
        cartDetails: {},
        cartDetailsErrorMessage: "",
      };
    default:
      return state;
  }
}