import {
  RESEND_LINK_REQUEST,
  RESEND_LINK_SUCCESS,
  RESEND_LINK_ERROR,
  CLEAR_RESEND_LINK_FLAG,
} from "../type/ResendLinkType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  resendLinkLoading: false,
  resendLinkSuccess: false,
  resendLinkError: false,
  resendLinkErrorData: "",
};

export default function ResendLinkReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.ResendLinkReducer) {
        return { ...action.payload.ResendLinkReducer };
      }

      return {
        ...state,
      };
    case RESEND_LINK_REQUEST:
      return {
        ...state,
        resendLinkLoading: false,
        resendLinkSuccess: false,
        resendLinkError: false,
      };
    case RESEND_LINK_SUCCESS:
      return {
        ...state,
        resendLinkLoading: false,
        resendLinkSuccess: true,
        resendLinkError: false,
      };
    case RESEND_LINK_ERROR:
      return {
        ...state,
        resendLinkLoading: false,
        resendLinkSuccess: false,
        resendLinkError: true,
        resendLinkErrorData: action.payload,
      };
    case CLEAR_RESEND_LINK_FLAG:
      return {
        resendLinkLoading: false,
        resendLinkSuccess: false,
        resendLinkError: false,
      };
    default:
      return state;
  }
}
