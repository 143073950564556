import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_PASWORD_REQUEST,
    UPDATE_USER_PASWORD_SUCCESS,
    UPDATE_USER_PASWORD_ERROR,
    CLEAR_USER_FLAG,
  } from "redux/type/UserType";

import { REHYDRATE } from "redux-persist/lib/constants";


const initialState = {
  getUserRequest: false,
  getUserSuccess: false,
  getUserError: false,
  userData: [],

  isUserLoading: false,
  
  updateUserRequest: false,
  updateUserSuccess: false,
  updateUserError: false,
  updateUserErrorMessage: '',

  updateUserPasswordRequest: false,
  updateUserPasswordSuccess: false,
  updateUserPasswordError: false
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
      case REHYDRATE: {
        if (action.payload && action.payload.userReducer) {
          return { ...action.payload.userReducer };
        }
        return {
          ...state,
        };
      }
      //get user
      case GET_USER_REQUEST:
        return {
          ...state,
          getUserRequest: true,
          getUserSuccess: false,
          getUserError: false,
        };

      case GET_USER_SUCCESS:
        return {
          ...state,
          getUserRequest: false,
          getUserSuccess: true,
          getUserError: false,
          userData: action.payload.data,
        };

      case GET_USER_ERROR:
        return {
          ...state,
          getUserRequest: false,
          getUserSuccess: false,
          getUserError: true,
          userData: [],
        };
  
      //update User
      case UPDATE_USER_REQUEST: 
        return {
          ...state,
          updateUserRequest: true,
          updateUserSuccess: false,
          updateUserError: false,
        };

      case UPDATE_USER_SUCCESS: 
        return {
          ...state,
          userData: action.payload,
          updateUserRequest: false,
          updateUserSuccess: true,
          updateUserError: false,
        };
      
      case UPDATE_USER_ERROR: 
        return {
          ...state,
          updateUserRequest: false,
          updateUserError: true,
          updateUserSuccess: false,
          updateUserErrorMessage: action.payload.response?.data?.message
        };

      //update User Password
      case UPDATE_USER_PASWORD_REQUEST:
      return {
        ...state,
        updateUserPasswordRequest: true,
        updateUserPasswordSuccess: false,
        updateUserPasswordError: false,
      };

      case UPDATE_USER_PASWORD_SUCCESS: 
      return {
        ...state,
        updateUserPasswordRequest: false,
        updateUserPasswordSuccess: true,
        updateUserPasswordError: false,
      };
    
      case UPDATE_USER_PASWORD_ERROR: 
      return {
        ...state,
        updateUserPasswordRequest: false,
        updateUserPasswordError: true,
        updateUserPasswordSuccess: false,
        updateUserErrorMessage: action.payload.response?.data?.message
      };
      
      //clear User flag
      case CLEAR_USER_FLAG:
        return {
          ...state,
          getUserRequest: false,
          getUserSuccess: false,
          getUserError: false,
          updateUserRequest: false,
          updateUserSuccess: false,
          updateUserError: false,
          updateUserPasswordRequest: false,
          updateUserPasswordSuccess: false,
          updateUserPasswordError: false,
          updateUserErrorMessage: ''
        };
      
      default:
        return state;
    }
  }
  