import {
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_ERROR,
  CLEAR_ADDRESSES_FLAG,
  ADD_ADDRESSES_REQUEST,
  ADD_ADDRESSES_SUCCESS,
  ADD_ADDRESSES_ERROR,
  UPDATE_ADDRESSES_REQUEST,
  UPDATE_ADDRESSES_SUCCESS,
  UPDATE_ADDRESSES_ERROR,
  DELETE_ADDRESSES_REQUEST,
  DELETE_ADDRESSES_SUCCESS,
  DELETE_ADDRESSES_ERROR,
} from "redux/type/AddressesType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  getAddressRequest: false,
  getAddressSuccess: false,
  getAddressError: false,
  addressData: [],

  addAddressData: [],
  addAddressRequest: false,
  addAddressSuccess: false,
  addAddressError: false,
  isAddressLoading: false,
  addAddressErrorMessage: {},
  

  updateAddress: [],
  isUpdateAddressLoading: false,
  updateAddressRequest: false,
  updateAddressSuccess: false,
  updateAddressError: false,

  deleteRequest: false,
  deleteAddressSuccess: false,
  deleteAddressError: false,
  deleteAddressMessage: [],
};

export default function addressReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.addressReducer) {
        return { ...action.payload.addressReducer };
      }
      return {
        ...state,
      };
    }
    //get Address
    case GET_ADDRESSES_REQUEST:
      return {
        ...state,
        getAddressRequest: true,
        getAddressSuccess: false,
        getAddressError: false,
      };
    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        getAddressRequest: false,
        getAddressSuccess: true,
        getAddressError: false,
        addressData: action.payload,
      };
    case GET_ADDRESSES_ERROR:
      return {
        ...state,
        getAddressRequest: false,
        getAddressSuccess: false,
        getAddressError: true,
        addressData: [],
      };
    //create Address
    case ADD_ADDRESSES_REQUEST:
      return {
        ...state,
        addAddressRequest: true,
        addAddressSuccess: false,
        addAddressError: false,
        isAddressLoading: true,
      };
    case ADD_ADDRESSES_SUCCESS:
      return {
        ...state,
        addAddressRequest: false,
        isAddressLoading: false,
        addAddressSuccess: true,
        addAddressData: action?.payload,
        addAddressError: false,
      };
    case ADD_ADDRESSES_ERROR:
      return {
        ...state,
        addAddressRequest: false,
        addAddressSuccess: false,
        addAddressError: true,
        isAddressLoading: false,
        addAddressErrorMessage: action.payload?.response?.data?.message
      };

    //update Address
    case UPDATE_ADDRESSES_REQUEST: {
      return {
        ...state,
        updateAddressRequest: true,
        isUpdateAddressLoading: true,
        updateAddressSuccess: false,
        updateAddressError: false,
      };
    }
    case UPDATE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        updateAddress: action.payload,
        updateAddressRequest: false,
        updateAddressSuccess: true,
        updateAddressError: false,
        isUpdateAddressLoading: false,
      };
    }
    case UPDATE_ADDRESSES_ERROR: {
      return {
        ...state,
        updateAddressRequest: false,
        updateAddressError: true,
        updateAddressSuccess: false,
        isUpdateAddressLoading: false,
      };
    }

    //delete Address

    case DELETE_ADDRESSES_REQUEST: {
      return {
        ...state,
        deleteRequest: true,
        deleteAddressSuccess: false,
        deleteAddressError: false,
      };
    }
    case DELETE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        deleteRequest: false,
        deleteAddressSuccess: true,
        deleteAddressError: false,
        deleteAddressMessage: action.payload,
      };
    }
    case DELETE_ADDRESSES_ERROR: {
      return {
        ...state,
        deleteRequest: false,
        deleteAddressSuccess: false,
        deleteAddressError: true,
      };
    }
    case CLEAR_ADDRESSES_FLAG:
      return {
        ...state,
        getAddressRequest: false,
        getAddressSuccess: false,
        getAddressError: false,
        deleteAddressSuccess: false,
        deleteAddressError: false,
        updateAddressSuccess: false,
        isUpdateAddressLoading: false,
        addAddressData: [],
        addAddressSuccess:false,
        addAddressRequest: false,
        // addressData: [],
        updateAddress: {},
        addAddressError: false,
        deleteAddressMessage: [],
      };
    default:
      return state;
  }
}
