export const GET_MEDICAL_MEDIA_REQUEST = "MEDICAL_MEDIA_REQUEST";
export const GET_MEDICAL_MEDIA_SUCCESS = "MEDICAL_MEDIA_SUCCESS";
export const GET_MEDICAL_MEDIA__ERROR = "MEDICAL_MEDIA__ERROR";
export const GET_MEDICAL_MEDIA__FLAG = "MEDICAL_MEDIA__FLAG"

export const UPLOAD_DOCUMENTS_REQUEST = "UPLOAD_DOCUMENTS_REQUEST";
export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_DOCUMENTS_SUCCESS";
export const UPLOAD_DOCUMENTS__ERROR = "UPLOAD_DOCUMENTS__ERROR";
export const UPLOAD_DOCUMENTS__FLAG = "UPLOAD_DOCUMENTS__FLAG"

export const GET_FILE_URL_REQUEST = "GET_FILE_URL_REQUEST";
export const GET_FILE_URL_SUCCESS = 'GET_FILE_URL_SUCCESS';
export const GET_FILE_URL_ERROR = 'GET_FILE_URL_URL_ERROR';
export const GET_FILE_URL_FLAG = 'GET_FILE_URL_FLAG'

export const DELETE_FILE_RECORD_REQUEST = "DELETE_FILE_RECORD_REQUEST";
export const DELETE_FILE_RECORD_SUCCESS = "DELETE_FILE_RECORD_SUCCESS";
export const DELETE_FILE_RECORD_ERROR = "DELETE_FILE_RECORD_ERROR";
export const DELETE_FILE_RECORD_FLAG = 'DELETE_FILE_RECORD_FLAG';
export const MEDICAL_HISTORY_FLAG = "MEDICAL_HISTORY"