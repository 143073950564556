export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";

export const GET_USER_ORDERS_REQUEST = "GET_USER_ORDERS_REQUEST";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS"
export const GET_USER_ORDERS_ERROR = "GET_USER_ORDERS_ERROR"

export const GET_USER_ORDERS_DETAIL_REQUEST = "GET_USER_ORDER_DETAIL_REQUEST";
export const GET_USER_ORDERS_DETAIL_SUCCESS = "GET_USER_ORDER_DETAIL_SUCCESS";
export const GET_USER_ORDERS_DETAIL_ERROR   = "GET_USER_ORDER_DETAIL_ERROR";

export const CLEAR_ORDER_FLAG = "CLEAR_ORDER_FLAG";
export const CLEAR_ORDER_DATA_FLAG = "CLEAR_ORDER_DATA_FLAG";

