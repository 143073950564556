import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_REQUEST,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  CLEAR_PRODUCTS_FLAG,
} from "../../redux/type/ProductType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  getProductsRequest: false,
  getProductsSuccess: false,
  getProductsError: false,
  productsData: [],

  getSingleProductRequest: false,
  getSingleProductSuccess: false,
  getSingleProductError: false,
  singleProductData: [],
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.productReducer) {
        return { ...action.payload.productReducer };
      }
      return {
        ...state,
      };
    }
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        getProductsRequest: true,
        getProductsSuccess: false,
        getProductsError: false,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        getProductsRequest: false,
        getProductsSuccess: true,
        getProductsError: false,
        productsData: action.payload,
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        getProductsRequest: false,
        getProductsSuccess: false,
        getProductsError: true,
      };
    case GET_SINGLE_PRODUCT_REQUEST:
      return {
        ...state,
        getSingleProductRequest: true,
        getSingleProductSuccess: false,
        getSingleProductError: false,
      };
    case GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        getSingleProductRequest: false,
        getSingleProductSuccess: true,
        getSingleProductError: false,
        singleProductData: action.payload,
      };
    case GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        getSingleProductRequest: false,
        getSingleProductSuccess: false,
        getSingleProductError: true,
      };
    case CLEAR_PRODUCTS_FLAG:
      return {
        ...state,
        getProductsRequest: false,
        getProductsSuccess: false,
        getProductsError: false,
        getSingleProductRequest: false,
        getSingleProductSuccess: false,
        getSingleProductError: false,
      };

    default:
      return state;
  }
}
