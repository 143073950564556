import {
    GET_PRODUCTGEOFENCE_REQUEST,
    GET_PRODUCTGEOFENCE_SUCCESS,
    GET_PRODUCTGEOFENCE_ERROR,
    CLEAR_PRODUCTGEOFENCE_FLAG,
  } from "../../redux/type/ProductGeofenceType";
  import { REHYDRATE } from "redux-persist/lib/constants";
  
  const initialState = {
    getProductGeofenceRequest: false,
    getProductGeofenceSuccess: false,
    getProductGeofenceError: false,
    productGeofenceData: [],
  };
  
  export default function productGeofenceReducer(state = initialState, action) {
    switch (action.type) {
      case REHYDRATE: {
        if (action.payload && action.payload.productGeofenceReducer) {
          return { ...action.payload.productGeofenceReducer };
        }
        return {
          ...state,
        };
      }
      case GET_PRODUCTGEOFENCE_REQUEST:
        return {
          ...state,
          getProductGeofenceRequest: true,
          getProductGeofenceSuccess: false,
          getProductGeofenceError: false,
        };
      case GET_PRODUCTGEOFENCE_SUCCESS:
        return {
          ...state,
          getProductGeofenceRequest: false,
          getProductGeofenceSuccess: true,
          getProductGeofenceError: false,
          productGeofenceData: action.payload,
        };
      case GET_PRODUCTGEOFENCE_ERROR:
        return {
          ...state,
          getProductGeofenceRequest: false,
          getProductGeofenceSuccess: false,
          getProductGeofenceError: true,
          productGeofenceData: []
        };
      case CLEAR_PRODUCTGEOFENCE_FLAG:
        return {
          ...state,
          getProductGeofenceRequest: false,
          getProductGeofenceSuccess: false,
          getProductGeofenceError: false,
        };
  
      default:
        return state;
    }
  }