import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_ERROR,
  GET_USER_ORDERS_REQUEST,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_ERROR,
  CLEAR_ORDER_FLAG,
  GET_USER_ORDERS_DETAIL_REQUEST,
  GET_USER_ORDERS_DETAIL_SUCCESS,
  GET_USER_ORDERS_DETAIL_ERROR,
  CLEAR_ORDER_DATA_FLAG
} from "../type/OrderType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  isOrderLoading: false,
  isOrderSuccess: false,
  isOrderError: false,
  orderData: [],
  orderDataError: [],

  isGetOrderLoading: false,
  isGetOrderSuccess: false,
  isGetOrderError: false,
  getOrderData: [],

  isGetUserOrderDetailLoading: false,
  isGetUserOrderDetailSuccess: false,
  isGetUserOrderDetailError: false,
  getUserOrderDetailData: []
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.orderReducer) {
        return { ...action.payload.orderReducer };
      }

      return {
        ...state,
      };
    }
    case ORDER_REQUEST:
      return {
        ...state,
        isOrderLoading: true,
        isOrderSuccess: false,
        isOrderError: false,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        isOrderLoading: false,
        isOrderSuccess: true,
        isOrderError: false,
        orderData: action.payload,
      };
    case ORDER_ERROR:
      return {
        ...state,
        isOrderLoading: false,
        isOrderSuccess: false,
        isOrderError: true,
        orderDataError: action.payload.response.data
      };
    case GET_USER_ORDERS_REQUEST:
      return {
        ...state,
        isGetOrderLoading: true,
        isGetOrderSuccess: false,
        isGetOrderError: false,
      };
    case GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        isGetOrderLoading: false,
        isGetOrderSuccess: true,
        isGetOrderError: false,
        getOrderData: action.payload,
      };
    case GET_USER_ORDERS_ERROR:
      return {
        ...state,
        isGetOrderLoading: false,
        isGetOrderSuccess: false,
        isGetOrderError: true,
      };
    case GET_USER_ORDERS_DETAIL_REQUEST:
      return {
        ...state,
        isGetUserOrderDetailLoading: true,
        isGetUserOrderDetailSuccess: false,
        isGetUserOrderDetailError: false,
      };
    case GET_USER_ORDERS_DETAIL_SUCCESS:
      return {
        ...state,
        isGetUserOrderDetailLoading: false,
        isGetUserOrderDetailSuccess: true,
        isGetUserOrderDetailError: false,
        getUserOrderDetailData: action.payload
        ,
      };
    case GET_USER_ORDERS_DETAIL_ERROR:
      return {
        ...state,
        isGetUserOrderDetailLoading: true,
        isGetUserOrderDetailSuccess: false,
        isGetUserOrderDetailError: false,
      }  
    case CLEAR_ORDER_FLAG:
      return {
        ...state,
        isOrderLoading: false,
        isOrderSuccess: false,
        isOrderError: false,
        isGetOrderLoading: false,
        isGetOrderSuccess: false,
        isGetOrderError: false,
        isGetUserOrderDetailLoading: false,
        isGetUserOrderDetailSuccess: false,
        isGetUserOrderDetailError: false,
      };
      case CLEAR_ORDER_DATA_FLAG:
        return {
          ...state,
          getOrderData:[]
        };
    default:
      return {
        ...state,
      };
  }
}
