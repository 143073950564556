import {
  LOCAL_ADD_TO_CART,
  DELETE_LOCAL_ADD_TO_CART,
  CLEAR_LOCAL_CART,
} from "../type/LocalCartType";
import { REHYDRATE } from "redux-persist/lib/constants";
import _ from "lodash";

const initialState = {
  localCartData: [],
  localCartSuccess: false,
};

export default function localCartReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.localCartReducer) {
        return { ...action.payload.localCartReducer };
      }

      return {
        ...state,
      };
    case LOCAL_ADD_TO_CART: {
      const data = state?.localCartData?.length
        ? _.uniqBy([...state.localCartData, ...action.payload], "product.id")
        : action.payload;
      const increase = _.map(action.payload, "product.increase");
      if (increase[0]) {
        const foundedIndex = _.findIndex(
          data,
          (item) => item.product.id === action?.payload[0]?.product?.id
        );
        if (foundedIndex > -1) {
          data[foundedIndex].quantity = data[foundedIndex].quantity + 1;
          data[foundedIndex].total =
            action.payload[0].product.price * data[foundedIndex].quantity * (data[foundedIndex].isProductSubscribed ? data[foundedIndex].duration : 1 );
        }
      }
      const decrease = _.map(action.payload, "product.decrease");
      if (decrease[0]) {
        const foundedIndex = _.findIndex(
          data,
          (item) => item.product.id === action?.payload[0]?.product?.id
        );
        if (foundedIndex > -1) {
          data[foundedIndex].quantity = data[foundedIndex].quantity - 1;
          data[foundedIndex].total =
            action.payload[0].product.price * data[foundedIndex].quantity * (data[foundedIndex].isProductSubscribed ? data[foundedIndex].duration : 1 );
        }
      }

      return {
        ...state,
        localCartData: data,
        localCartSuccess: true,

      };
    }
    case DELETE_LOCAL_ADD_TO_CART: {
      const index = _.findIndex(
        state.localCartData,
        (item) => item.product.id === action?.payload
      );
      state.localCartData.splice(index, 1);
      return {
        localCartData: state.localCartData,
        localCartSuccess: true,
      };
    }
    case CLEAR_LOCAL_CART: {
      return {
        localCartData: [],
        localCartSuccess: false,
      };
    }
    default:
      return state;
  }
}
