import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  CLEAR_LOG_IN_FLAG
} from "../../redux/type/LoginType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  loginSuccess: false,
  loginError: false,
  verifySuccess: false,
  isLoading: false,
  isLogInLoading: false,
  loginData: "",
  errorData: "",
  errorCode: ""
};

export default function logInReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.logInReducer) {
        return { ...action.payload.logInReducer };
      }

      return {
        ...state
      };
    case LOG_IN_REQUEST:
      return {
        ...state,
        loginSuccess: false,
        loginError: false,
        isLogInLoading: true
      };
    case LOG_IN_SUCCESS: {
      localStorage.setItem("token", action.payload.jwt);
      localStorage.setItem("userName", action.payload.user.fullName);
      localStorage.setItem("userId", action?.payload?.user?.id);
      localStorage.setItem("isVerified", false);
      return {
        ...state,
        loginSuccess: true,
        loginError: false,
        isLogInLoading: false,
        loginData: action.payload.user
      };
    }
    case LOG_IN_ERROR: {
      return {
        ...state,
        loginSuccess: false,
        loginError: true,
        isLogInLoading: false,
        errorCode: action.payload.response
      };
    }

    case VERIFY_USER_REQUEST:
      return {
        ...state,
        verifySuccess: false,
        isLoading: true
      };
    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verifySuccess: true,
        userData: action.payload.data.verifyUser,
        isLoading: false
      };
    }
    case VERIFY_USER_ERROR: {
      return {
        ...state,
        verifySuccess: false
      };
    }
    case CLEAR_LOG_IN_FLAG: {
      return {
        ...state,
        verifySuccess: false,
        loginSuccess: false,
        loginError: false,
        isLogInLoading: false
      };
    }
    default:
      return {
        ...state
      };
  }
}
