import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  CLEAR_SIGN_UP_FLAG,
} from "../../redux/type/SignUpType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  isSignUpLoading: false,
  signUpSuccess: false,
  signUpError: false,
  signUpData: "",
  errorCode: ""
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.signUpReducer) {
        return { ...action.payload.signUpReducer };
      }
      return {
        ...state,
      };
    }
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: true,
        signUpError: false,
      };
    case SIGN_UP_SUCCESS:
      // localStorage.setItem("token", action.payload.jwt);
      // localStorage.setItem("LoggedIn", true);
      // localStorage.setItem("userName", action.payload.user.fullName);
      return {
        ...state,
        signUpSuccess: true,
        isSignUpLoading: false,
        signUpError: false,
        signUpData: action.payload.user,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: false,
        signUpError: true,
        errorCode: action.payload.response
      };
    case CLEAR_SIGN_UP_FLAG:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: false,
        signUpError: false,
      };

    default:
      return state;
  }
}
