import { combineReducers } from "redux";
import contactReducer from "./reducers/ContactReducer";
import cartReducer from "./reducers/OrderCartReducer";
import signUpReducer from "./reducers/SignUpReducer";
import logInReducer from "./reducers/LoginReducer";
import promoCodeReducer from "./reducers/PromoReducer";
import paymentReducer from "./reducers/PaymentReducer";
import orderReducer from "./reducers/OrderReducer";
import selfAssessmentReducer from "./reducers/SelfAssessmentReducer";
import checkAssessmentReducer from "./reducers/CheckAssessmentReducer";
import productReducer from "./reducers/ProductsReducer";
import forgotReducer from "./reducers/ForgotPasswordReducer";
import resendLinkReducer from "./reducers/ResendLinkReducer";
import localCartReducer from "./reducers/LocalCartReducer";
import MedicalMediaUploadReducer from "./reducers/MedicalMediaUploadReducer"
import productGeofenceReducer from "./reducers/ProductGeofenceReducer";
import addressReducer from "./reducers/AddressReducer";
import ManageMyProfileReducer from "./reducers/ManageMyProfileReducer"
import userReducer from "./reducers/userReducer";

export default combineReducers({
  contactReducer,
  cartReducer,
  signUpReducer,
  logInReducer,
  promoCodeReducer,
  paymentReducer,
  orderReducer,
  selfAssessmentReducer,
  checkAssessmentReducer,
  productReducer,
  forgotReducer,
  resendLinkReducer,
  localCartReducer,
  productGeofenceReducer,
  MedicalMediaUploadReducer,
  addressReducer,
  userReducer,
  ManageMyProfileReducer,
});
