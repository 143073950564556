

export const endpoints = {
    signIn: '/sign-in',
    userLogin: 'auth/local',
    carts: 'carts',
    getFileURL: 'getFileURL',
    mediaList: "mediaList",
    uploadDocument: "uploadDocument",
    deleteFile: "deleteFile",
    userOrders: "user/orders",
    createOrders: "orders",
    users: "users",
    getAddresses: "addresses",
    deleteAddress: "address",
    createAddress: "addresses",
    updateAddress: "address",
    updatePassword: "updatePassword",
    updateUserInfo: "updateUserInfo",
    userCart: "user/carts",
    getProducts: "products",
    register: "auth/local/register",
    emailVerification: "/auth/email-confirmation",
    orderDetailByID: "/findOrderById"
}
export const host = {
    IPFS: 'https://ipfs.webelight.co.in/',
    futureFarma: 'https://futurefarma-api.nceptio.com/'
}
export const routes = {
    signIn: "/sign-in",
    orderDetails: "/order-details",
    myProfile: "/my-profile",
    forgetPassword: "/forgot-password/email",
    products: "/products",
    productDetail: "/product-detail",
    orderPlaced: "/order-placed",
    subscriptionPackages: "/subscription-packages",
    contactUs: "/contact-us",
    signUp: "/sign-up",
    subscriptionConfirmation: "/subscription-confirmation",
    products: "/products",
    medicalHistory: "/medical-history",
    carts: "/cart",
    privacyPolicy: "/privacy-policy",
    myOrders: "/my-order",
    myOrdersDetails: "/my-order-detail",
    mySubscription: "/my-subscription",
    forgotPassword: "/forgot-password",
    verification: "/verification",
    orderConfirmation: "/order-confirmation",
    shippingInfo: "/shipping-information",
    

}
