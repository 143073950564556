export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_USER_PASWORD_REQUEST = "UPDATE_USER_PASWORD_REQUEST";
export const UPDATE_USER_PASWORD_SUCCESS = "UPDATE_USER_PASWORD_SUCCESS";
export const UPDATE_USER_PASWORD_ERROR = "UPDATE_USER_PASWORD_ERROR";

export const CLEAR_USER_FLAG = "CLEAR_USER_FLAG";